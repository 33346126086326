import React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import ArticleWrapper from './styled';

const ArticleMeta = ({ category, date, tags }) => {

  return (
    <ArticleWrapper>
      <p className="category_date">
        <span className="category">
          <Link to={`/${_.kebabCase(category)}`} >{category}</Link>
        </span>
        <span className="date">{date}</span>
      </p>
      <p className="tags">
        {tags.map((tag) => {
          return (
            <Link key={tag} to={`/tag/${_.kebabCase(tag)}`} className="tag">{tag}</Link>
          );
        })}
      </p>
    </ArticleWrapper>
  );
};

export default ArticleMeta;
